<template>
    <with-addon-before-info-panel
        title="缓缴"
        class="has-margin-top"
        :data-source="dataSource"
        :columns="contentColumns"
        :addon-before-columns="addonBeforeColumns"
    />
</template>
<script>
import WithAddonBeforeInfoPanel from '@weights/WithAddonBeforeInfoPanel'

import { ADDON_BEFORE_COLUMNS, CONTENT_COLUMNS } from './config'

export default {
    name: 'DeferreedPayment',
    components: {
        WithAddonBeforeInfoPanel
    },
    props: {
        dataSource: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            // 主体内容项配置
            contentColumns: Object.freeze(CONTENT_COLUMNS),
            // 前置内容项配置
            addonBeforeColumns: Object.freeze(ADDON_BEFORE_COLUMNS)
        }
    }
}
</script>
