import { empty as emptyFilter } from '@utils'
import { withYuanUnitAmountFilter } from '@utils/filter'

// 前置内容项配置
export const ADDON_BEFORE_COLUMNS = [
    {
        dataIndex: 'deferreedAmount',
        label: '金额',
        customRender: val => withYuanUnitAmountFilter(val)
    }
]

// 主体内容项配置
export const CONTENT_COLUMNS = [
    {
        dataIndex: 'deferreedAmount',
        label: '缓缴金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'deferredStartDateAndEndDateText',
        label: '缓缴日期',
        customRender: val => emptyFilter(val)
    }
]
